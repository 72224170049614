import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { NextUIProvider } from "@nextui-org/react";

import "./scss/style.scss";
import "./App.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";
import "dayjs/locale/pl";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { SnackbarProvider } from "notistack";

import AuthService from "services/AuthService";
import CurrentUser from "services/CurrentUser";
import { LoadingSpinner, PageNotFound } from "components/common";

import { Login } from "pages/Login";
import { SignUp } from "pages/SignUp/SignUp";

import { CalendarPage, HomePage, PaymentStatusPage } from "pages/common";

import {
    ActivityAddPage,
    ActivityEditPage,
    BillingsPage,
    BrandAddPage,
    BrandEditPage,
    BrandsPage,
    TrainerAddPage,
    TrainerEditPage,
} from "pages/Brand";

import {
    ActivitiesPage as InstitutionActivitiesPage,
    InstitutionPage,
    InstitutionAddPage,
    InstitutionEditPage,
    KidsPage as InstitutionKidsPage,
    KidAddPage as InstitutionKidAddPage,
    KidEditPage as InstitutionKidEditPage,
    TeachersPage,
    TeacherAddPage,
    TeacherEditPage,
} from "pages/Institution";

import { ActivitiesPage, KidsPage, KidAddPage, KidEditPage, InstitutionsPage } from "pages/Parent";
import { ProfilePage } from "pages/common/ProfilePage";
import UpdatePasswordModal from "components/account/UpdatePasswordModal";
import { EnrollmentsPage } from "pages/Brand/EnrollmentsPage";
import { PaymentPage } from "pages/common/PaymentPage";
import { BillingsPage as AdminBillingsPage, StatisticsPage, UsersPage } from "pages/Admin";
import { Maintenance } from "Maintenance";

dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

function App() {
    const [isLoading, setLoading] = useState(true);
    const [maintenance, setMaintenance] = useState(false);

    const { i18n } = useTranslation();
    const location = useLocation();
    const currentUser = CurrentUser();

    const allowedSlugs = ["/login", "/signup", "/signup/parent", "/signup/institution", "/signup/brand"];

    dayjs.locale(i18n.language);

    const getData = async () => {
        await axios.get(process.env.REACT_APP_API_URL + "/maintenance").then((res) => {
            setMaintenance(res.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (true === isLoading) {
        return <LoadingSpinner isFull={true} />;
    }

    if (true === maintenance) {
        return <Maintenance />;
    }

    if (!AuthService.isToken() && !allowedSlugs.includes(location.pathname)) {
        return <Navigate to="/login" replace={true} />;
    } else if (currentUser != null && currentUser.accountType === "institution") {
        if (currentUser.institution === null && location.pathname !== "/institution/new") {
            return <Navigate to={"/institution/new"} replace={true} />;
        } else if (currentUser.institution != null && location.pathname === "/institution/new") {
            return <Navigate to={"/institution/my"} replace={true} />;
        }
    }

    return (
        <NextUIProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                    {currentUser != null && currentUser.updatePassword === true ? <UpdatePasswordModal /> : null}

                    <Routes>
                        <Route exact path="login" element={<Login />} />
                        <Route exact path="signup" element={<SignUp type={null} />} />
                        <Route exact path="signup/parent" element={<SignUp type={1} />} />
                        <Route exact path="signup/institution" element={<SignUp type={2} />} />
                        <Route exact path="signup/brand" element={<SignUp type={3} />} />

                        <Route path="/" element={<HomePage />} />
                        <Route path="account/profile" element={<ProfilePage />} />
                        <Route path="calendar" element={<CalendarPage />} />

                        {currentUser && (
                            <>
                                {currentUser.roles.includes("ROLE_ADMIN") && (
                                    <>
                                        {currentUser.roles.includes("ROLE_ADMIN") && (
                                            <>
                                                <Route path="admin/statistics" element={<StatisticsPage />} />
                                                <Route path="admin/users" element={<UsersPage />} />
                                                <Route path="admin/billings" element={<AdminBillingsPage />} />
                                            </>
                                        )}

                                        {currentUser.accountType === "brand" && (
                                            <>
                                                <Route path="brand/brands" element={<BrandsPage />} />
                                                <Route path="brand/brands/new" element={<BrandAddPage />} />
                                                <Route path="brand/brands/:brandId/edit" element={<BrandEditPage />} />
                                                <Route
                                                    path="brand/brands/:brandId/activities/new"
                                                    element={<ActivityAddPage />}
                                                />
                                                <Route
                                                    path="brand/brands/:brandId/activities/:activityId/edit"
                                                    element={<ActivityEditPage />}
                                                />
                                                <Route
                                                    path="brand/brands/:brandId/trainers/new"
                                                    element={<TrainerAddPage />}
                                                />
                                                <Route
                                                    path="brand/brands/:brandId/trainers/:trainerId/edit"
                                                    element={<TrainerEditPage />}
                                                />
                                                <Route path="brand/billings" element={<BillingsPage />} />
                                                <Route path="brand/enrollments" element={<EnrollmentsPage />} />
                                            </>
                                        )}

                                        {currentUser.accountType === "institution" && (
                                            <>
                                                <Route path="institution/my" element={<InstitutionPage />} />
                                                <Route path="institution/new" element={<InstitutionAddPage />} />
                                                <Route path="institution/edit" element={<InstitutionEditPage />} />
                                                <Route path="institution/kids" element={<InstitutionKidsPage />} />
                                                <Route
                                                    path="institution/kids/new"
                                                    element={<InstitutionKidAddPage />}
                                                />
                                                <Route
                                                    path="institution/kids/:kidId/edit"
                                                    element={<InstitutionKidEditPage />}
                                                />

                                                <Route path="institution/teachers" element={<TeachersPage />} />
                                                <Route path="institution/teachers/new" element={<TeacherAddPage />} />
                                                <Route
                                                    path="institution/teachers/:teacherId/edit"
                                                    element={<TeacherEditPage />}
                                                />

                                                <Route
                                                    path="institution/activities"
                                                    element={<InstitutionActivitiesPage />}
                                                />
                                            </>
                                        )}

                                        {currentUser.accountType === "parent" && (
                                            <>
                                                <Route path="parent/activities" element={<ActivitiesPage />} />
                                                <Route path="parent/institutions" element={<InstitutionsPage />} />
                                                <Route path="parent/kids" element={<KidsPage />} />
                                                <Route path="parent/kids/new" element={<KidAddPage />} />
                                                <Route path="parent/kids/:kidId/edit" element={<KidEditPage />} />
                                            </>
                                        )}

                                        <Route path="payment/:orderId" element={<PaymentPage />} />
                                        <Route path="payment-status/:orderId" element={<PaymentStatusPage />} />
                                    </>
                                )}

                                {currentUser.accountType === "brand" && (
                                    <>
                                        <Route path="brand/brands" element={<BrandsPage />} />
                                        <Route path="brand/brands/new" element={<BrandAddPage />} />
                                        <Route path="brand/brands/:brandId/edit" element={<BrandEditPage />} />
                                        <Route
                                            path="brand/brands/:brandId/activities/new"
                                            element={<ActivityAddPage />}
                                        />
                                        <Route
                                            path="brand/brands/:brandId/activities/:activityId/edit"
                                            element={<ActivityEditPage />}
                                        />
                                        <Route path="brand/brands/:brandId/trainers/new" element={<TrainerAddPage />} />
                                        <Route
                                            path="brand/brands/:brandId/trainers/:trainerId/edit"
                                            element={<TrainerEditPage />}
                                        />
                                        <Route path="brand/billings" element={<BillingsPage />} />
                                        <Route path="brand/enrollments" element={<EnrollmentsPage />} />
                                    </>
                                )}

                                {currentUser.accountType === "institution" && (
                                    <>
                                        <Route path="institution/my" element={<InstitutionPage />} />
                                        <Route path="institution/new" element={<InstitutionAddPage />} />
                                        <Route path="institution/edit" element={<InstitutionEditPage />} />
                                        <Route path="institution/kids" element={<InstitutionKidsPage />} />
                                        <Route path="institution/kids/new" element={<InstitutionKidAddPage />} />
                                        <Route
                                            path="institution/kids/:kidId/edit"
                                            element={<InstitutionKidEditPage />}
                                        />

                                        <Route path="institution/teachers" element={<TeachersPage />} />
                                        <Route path="institution/teachers/new" element={<TeacherAddPage />} />
                                        <Route
                                            path="institution/teachers/:teacherId/edit"
                                            element={<TeacherEditPage />}
                                        />

                                        <Route path="institution/activities" element={<InstitutionActivitiesPage />} />
                                    </>
                                )}

                                {currentUser.accountType === "parent" && (
                                    <>
                                        <Route path="parent/activities" element={<ActivitiesPage />} />
                                        <Route path="parent/institutions" element={<InstitutionsPage />} />
                                        <Route path="parent/kids" element={<KidsPage />} />
                                        <Route path="parent/kids/new" element={<KidAddPage />} />
                                        <Route path="parent/kids/:kidId/edit" element={<KidEditPage />} />
                                    </>
                                )}

                                <Route path="payment/:orderId" element={<PaymentPage />} />
                                <Route path="payment-status/:orderId" element={<PaymentStatusPage />} />
                            </>
                        )}

                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </SnackbarProvider>
            </LocalizationProvider>
        </NextUIProvider>
    );
}

export default App;
