import React, { useEffect, useRef, useState } from "react";

import { LoadingSpinner } from "components/common";

const formStyle = {
    layout: "default",
    theme: "default",
    color: {
        primary: "#94FA46",
        secondary: "",
        border: "",
        surface: "",
        header: {
            text: "",
            background: "",
        },
        body: {
            text: "#313333",
            background: "",
        },
    },
    font: "",
};

export const PayTelForm = ({ data }) => {
    const [isLoading, setLoading] = useState(true);

    const intervalRef = useRef();

    useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = process.env.REACT_APP_PAYTEL_SCRIPT.replace("{transactionID}", data.transactionID);
        document.body.appendChild(scriptTag);

        setTimeout(() => {
            intervalRef.current = setInterval(() => {
                let targetFrame = window.top.frames;
                console.log(targetFrame);

                if (targetFrame) {
                    let spgframe = document.getElementById("spgframe");
                    spgframe.height = "100%";

                    clearInterval(intervalRef.current);
                    intervalRef.current = null;

                    setLoading(false);
                }
            }, 1000);
        }, 3000);
    }, [data.transactionID]);

    return (
        <div
            className={"box flex min-h-80 w-full max-w-4xl !p-0 overflow-hidden relative"}
            style={{ height: "calc(100vh - 40px - 8rem)" }}
        >
            {isLoading && (
                <div className="absolute w-full h-full bg-white">
                    <LoadingSpinner />
                </div>
            )}
            <form
                id="spgGateway"
                className={"p-4 w-full overflow-y-auto box-content paymentSPG"}
                spg-context={data.formContext}
                spg-config={JSON.stringify(data.formConfig)}
                spg-style={JSON.stringify(formStyle)}
            ></form>
        </div>
    );
};
