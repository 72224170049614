import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IoAccessibilityOutline, IoPeopleOutline } from "react-icons/io5";

import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import { Badge, ContentHeading, OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";
import ModalEnrollmentTrainer from "./ModalEnrollmentTrainer";

function EnrollmentList({ enrollments, handleLoadData }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null });

    const columns = [
        {
            field: "activity",
            headerName: t("Activities", { ns: "common" }),
            width: 320,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            sortable: false,
            valueFormatter: (value) => `${t(value.name, { ns: "lexicons" })}`,
            renderCell: (params) => {
                let color = "";
                switch (params.row.status.value) {
                    case "OPEN":
                        color = "#0284c7";
                        break;
                    case "ACTIVE":
                    case "ENROLLED":
                        color = "#166534";
                        break;
                    case "CANCELLED":
                        color = "#313333";
                        break;
                    default:
                        // color = "#ca8a04";
                        color = "#dc2626";
                        break;
                }

                return (
                    <div className="flex items-center h-full">
                        <Badge color={color}>{t(params.row.status.name, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "institution",
            headerName: t("Institution", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "trainers",
            headerName: t("Trainers", { ns: "common" }),
            width: 240,
            valueGetter: (value, row) => {
                let trainers = row.date
                    .map((d) => {
                        return d.trainers
                            .map((t) => {
                                return t.fullName;
                            })
                            .join(", ");
                    })
                    .join(", ");

                return trainers ? trainers : "---";
            },
        },
        {
            field: "date",
            headerName: t("Date of activity", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            sortable: false,
            valueGetter: (value, row) => {
                if (row.activity.period.value === "SINGLE") {
                    return `${row.date.map((d) => d) || ""}`;
                } else {
                    return `${row.date.map((d) => t(d.day.name, { ns: "lexicons" }) + " - " + d.time) || ""}`;
                }
            },
            renderCell: (params) => {
                return params.row.activity.period.value === "SINGLE" ? (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDateTime date={d.day} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                ) : (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDayTime day={d.day.name} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            disableExport: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <OptionMenu
                        options={[
                            {
                                text: t("Trainers", { ns: "button" }),
                                icon: <IoPeopleOutline size={"1.25em"} />,
                                menuItemProps: {
                                    onClick: () => openModal(params.row, "updatedTrainers"),
                                },
                            },
                        ]}
                    />
                );
            },
        },
    ];

    const openModal = (enrollment, type = "view") => {
        setModal({ show: true, data: enrollment, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoAccessibilityOutline size={"1.5rem"} />
                {t("Enrollments", { ns: "common" })}
            </ContentHeading>

            <DataGrid rows={enrollments} columns={columns} enableSearch={true} fileName={"ProwadzoneZajęcia"} />

            {modal.show && modal.data && modal.type === "updatedTrainers" && (
                <ModalEnrollmentTrainer
                    data={modal.data}
                    closeModal={handleModalClose}
                    handleLoadData={handleLoadData}
                />
            )}
        </>
    );
}

export default EnrollmentList;
